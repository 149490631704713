<template>
  <a-modal
    id="view-schedule-modal"
    v-model:visible="showViewScheduleModal"
    class="w-75 fixed-height-modal"
    :mask-closable="false"
    :footer="null"
    destroy-on-close
    @cancel="handleCancelViewSchedule"
  >
    <a-card title="Schedule Modes" size="default" hoverable>
      <a-form
        class="w-100 d-flex justify-content-around"
        style="overflow: auto"
      >
        <a-form-item label="Day(s)" style="width: 20%">
          <a-select
            id="select-schedule-days-input"
            v-model:value="selectedDays"
            mode="multiple"
            placeholder="Select day"
            :options="daysOptions"
          />
        </a-form-item>
        <a-form-item label="Mode" class="ml-3" style="width: 20%">
          <a-select
            id="select-schedule-days-input"
            v-model:value="selectedMode"
            placeholder="Select Mode"
            :options="schedulingModeOptions"
          />
        </a-form-item>
        <a-form-item label="Interval" style="width: 20%">
          <a-time-range-picker
            id="dm-time-range-input"
            v-model:value="startEndTime"
            class="w-100"
            format="HH:mm"
            style="width: 400px"
          />
        </a-form-item>
        <a-form-item style="width: 15%">
          <div class="d-flex justify-content-around align-items-center">
            <a-tooltip title="Fetch Schedules">
              <reload-outlined
                style="margin-right: 20px"
                @click="sendScheduleToSQS"
              />
            </a-tooltip>
            <a-button type="primary" @click="scheduleRecordings">
              Schedule
            </a-button>
            <a-button @click="resetScheduleState"> Reset </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-card>
    <a-table
      id="view-schedule-table"
      bordered
      :columns="viewScheduleTableColumns"
      :data-source="sortedScheduleRecordingsData"
      :loading="isLoadingSchedule"
      class="w-100 fixed-height-table"
      :pagination="{ position: ['bottomCenter'] }"
      :scroll="{ y: 240 }"
    >
      <template #bodyCell="{ column, record }">
        <!-- Editable Data-->
        <template v-if="isEditing[record.id]">
          <template v-if="column.dataIndex === 'day'">
            <a-select
              v-model:value="record.day"
              :options="daysOptions"
              placeholder="Select Day"
              style="width: 100%"
            />
          </template>

          <template v-if="column.dataIndex === 'start_time'">
            <a-time-picker
              v-model:value="updatedStartTime"
              format="HH:mm"
              placeholder="Start Time"
              style="width: 100%"
            />
          </template>

          <template v-if="column.dataIndex === 'end_time'">
            <a-time-picker
              v-model:value="updatedEndTime"
              format="HH:mm"
              placeholder="End Time"
              style="width: 100%"
            />
          </template>

          <template v-if="column.dataIndex === 'mode'">
            <a-select
              v-model:value="record.mode"
              :options="schedulingModeOptions"
              placeholder="Select Mode"
              style="width: 100%"
            />
          </template>

          <template v-if="column.dataIndex === 'is_active'">
            <a-switch v-model:checked="record.is_active" />
          </template>
          <template v-if="column.dataIndex === 'is_running'">
            <a-switch v-model:checked="record.is_running" disabled />
          </template>
        </template>

        <!-- Non-Editing Data -->
        <template v-else>
          <template v-if="column.dataIndex === 'day'">{{
            record.day
          }}</template>
          <template v-if="column.dataIndex === 'start_time'">{{
            record.start_time
              ? dateHelper.convertUTCToLocal(record.start_time)
              : 'Not available'
          }}</template>
          <template v-if="column.dataIndex === 'end_time'">{{
            record.end_time
              ? dateHelper.convertUTCToLocal(record.end_time)
              : 'Not available'
          }}</template>
          <template v-if="column.dataIndex === 'is_running'">
            <a-tag :color="record.is_running ? 'green' : 'default'">{{
              record.is_running ? 'running' : 'not running'
            }}</a-tag>
          </template>
          <template v-if="column.dataIndex === 'is_active'">
            <a-tag :color="record.is_active ? 'green' : 'default'">{{
              record.is_active ? 'active' : 'inactive'
            }}</a-tag>
          </template>
          <template v-if="column.dataIndex === 'mode'">{{
            record.mode
          }}</template>
        </template>

        <!-- Actions Column -->
        <template v-if="column.dataIndex === 'action'">
          <a-space>
            <a-button
              v-if="!isEditing[record.id]"
              id="edit-schedule-btn"
              :disabled="recordToUpdate"
              @click="enableEdit(record)"
            >
              <form-outlined />
            </a-button>
            <a-popconfirm
              :title="
                record.is_running
                  ? 'The schedule is running now. Would you like to make updates?'
                  : 'Are you sure you want to update this schedule?'
              "
              ok-text="Yes"
              cancel-text="No"
              @confirm="saveEdit(record)"
            >
              <a-button
                v-if="isEditing[record.id] && recordToUpdate"
                :disabled="!recordToUpdate"
              >
                Save
              </a-button>
            </a-popconfirm>
            <a-button
              v-if="isEditing[record.id] && recordToUpdate"
              @click="cancelUpdate"
            >
              cancel
            </a-button>
            <a-popconfirm
              :disabled="recordToUpdate"
              title="Are you sure you want to delete this schedule?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDeleteSchedule(record)"
            >
              <a-button
                danger
                block
                :disabled="recordToUpdate"
                :loading="isDelete[record.id]"
              >
                <delete-outlined />
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
import {
  daysOptions,
  schedulingModeOptions,
  viewScheduleTableColumns,
} from '@/config/schedule-config';
import DeviceService from '@/services/device';
import {
  DeleteOutlined,
  FormOutlined,
  ReloadOutlined,
} from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import fcm from 'src/services/fcm';
import { ref } from 'vue';
export default {
  components: {
    FormOutlined,
    DeleteOutlined,
    ReloadOutlined,
  },
  inject: ['toast'],
  props: ['showSchedule', 'selectedDevice', 'selectedDeviceStatus'],
  emits: ['setDisplayViewSchedule'],
  setup() {
    return {
      viewScheduleTableColumns,
      dateHelper,
      daysOptions,
      schedulingModeOptions,
    };
  },
  data() {
    return {
      showViewScheduleModal: false,
      isLoadingSchedule: false,
      isDelete: {},
      scheduleRecordingsData: [],
      selectedDays: [],
      selectedMode: null,
      isUpdateSchedule: false,
      isEditing: {},
      recordToUpdate: null,
      deviceId: null,
      updatedStartTime: null,
      updatedEndTime: null,
      startEndTime: ref([]),
    };
  },
  computed: {
    sortedScheduleRecordingsData() {
      const daysOrder = daysOptions.map((option) => option.value);
      return this.scheduleRecordingsData.sort(
        (a, b) => daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day)
      );
    },
  },
  watch: {
    async showSchedule(value) {
      this.showViewScheduleModal = value;
      if (value) await this.getDeviceSchedule();
    },
    // updateSchedule: {
    //   deep: true,
    //   handler(value) {
    //     if (!value?.isUpdated) return;
    //     this.updateScheduleList(value);
    //   },
    // },
    selectedDeviceStatus(device_status) {
      if (!this.scheduleRecordingsData) return;

      if (
        Object.keys(device_status?.running_schedule || {}).length === 0 ||
        device_status?.running_schedule == null
      ) {
        this.scheduleRecordingsData.forEach((record) => {
          record.is_running = false;
        });
        return;
      }

      const scheduleId = device_status.running_schedule.id;

      const schedule = this.scheduleRecordingsData.find(
        (record) => record.id === scheduleId
      );

      if (schedule) {
        schedule.is_running = device_status.running_schedule.is_running;
      }
    },
  },
  mounted() {
    this.showViewScheduleModal = this.showSchedule;
    this.selectDeviceId();
  },
  methods: {
    selectDeviceId() {
      this.deviceId =
        typeof this.selectedDevice === 'object' && this.selectedDevice !== null
          ? this.selectedDevice.id
          : this.selectedDevice;
    },
    handleCancelViewSchedule() {
      this.$emit('setDisplayViewSchedule', false);
      this.cancelUpdate();
    },
    async getDeviceSchedule() {
      if (!this.selectedDevice) return;

      this.isLoadingSchedule = true;
      this.selectDeviceId();

      const [error, data] = await DeviceService.getDeviceSchedule(
        this.deviceId,
        true
      );
      if (error) {
        this.toast.error('Failed to fetch schedule details.');
      }
      this.scheduleRecordingsData = data;
      this.isLoadingSchedule = false;
    },
    async handleDeleteSchedule(record) {
      this.isDelete[record.id] = true;
      const [error] = await DeviceService.deleteDeviceSchedule(record.id);
      delete this.isDelete[record.id];
      if (error) {
        this.toast.error('Failed to delete the schedule.');
        return;
      }
      this.removeScheduleFromList(record);
    },
    async removeScheduleFromList(record) {
      const temp = this.scheduleRecordingsData.filter(
        (item) => item.id !== record.id
      );
      this.scheduleRecordingsData = [...temp];
      await this.getDeviceSchedule();
    },
    updateScheduleList(record) {
      let schedule = this.scheduleRecordingsData.find(
        (item) => item.id === record.id
      );
      if (schedule) {
        Object.assign(schedule, record);
      }
    },
    async scheduleRecordings() {
      const payload = this.generateSchedulePayload();
      const [error, data] = await DeviceService.addDeviceSchedule(payload);
      if (error) {
        this.toast.error(error.response.data);
        return;
      }
      await this.getDeviceSchedule();
      this.resetScheduleState();
      this.toast.success('Successfully added schedule.');
    },
    async sendScheduleToSQS() {
      const payload = {
        type: 'sync_schedule',
        message_time: new Date().toISOString(),
        platform: 'portal',
        organization: localStorage.getItem('organization'),
        device_id: this.selectedDevice?.Serial_number,
      };
      const [error] = await fcm.sendMessageToSQS(payload, false);
      if (error) throw 'Error while sending SQS message for Schedules';
    },
    resetScheduleState() {
      this.selectedDays = [];
      this.selectedMode = null;
      this.startEndTime = [];
    },
    generateSchedulePayload() {
      this.selectDeviceId();
      const [start, end] = dateHelper.getStartEndTimeForSchedule(
        this.startEndTime
      );
      const schedulePayload = [];
      this.selectedDays.map((day) => {
        schedulePayload.push({
          start_time: dateHelper.convertLocalToUTC(start),
          end_time: dateHelper.convertLocalToUTC(end),
          day: day,
          mode: this.selectedMode,
          device: this.deviceId,
        });
      });
      return schedulePayload;
    },

    enableEdit(record) {
      this.recordToUpdate = { ...record };
      this.isEditing[record.id] = true;

      this.updatedStartTime = dayjs(record.start_time);
      this.updatedEndTime = dayjs(record.end_time);
    },

    async saveEdit(record) {
      if (!this.updatedStartTime || !this.updatedEndTime) {
        this.toast.warning('Time Intervals are not Selected!');
        return;
      }
      if (this.updatedStartTime >= this.updatedEndTime) {
        this.toast.warning('Start Time should be greater than End Time!');
        return;
      }
      const updateSchedulePayload = this.generateUpdatePayload(record);
      const [error, data] = await DeviceService.updateDeviceSchedule(
        record.id,
        updateSchedulePayload
      );
      if (error) {
        this.toast.error('Failed to update schedule.');
        return;
      }
      await this.getDeviceSchedule();
      this.toast.success('Successfully updated schedule.');
      this.isEditing[record.id] = false;
      this.recordToUpdate = null;
      this.updatedStartTime = null;
      this.updatedEndTime = null;
    },
    generateUpdatePayload(record) {
      this.selectDeviceId();
      const updateSchedulePayload = {
        start_time: dateHelper.convertLocalToUTC(this.updatedStartTime),
        end_time: dateHelper.convertLocalToUTC(this.updatedEndTime),
        day: record.day,
        mode: record.mode,
        device: this.deviceId,
        is_active: record.is_active,
      };

      return updateSchedulePayload;
    },
    cancelUpdate() {
      if (!this.recordToUpdate) return;
      this.isEditing[this.recordToUpdate.id] = false;
      const index = this.scheduleRecordingsData.findIndex(
        (record) => record.id === this.recordToUpdate.id
      );
      if (index !== -1) {
        this.scheduleRecordingsData[index] = {
          ...this.scheduleRecordingsData[index],
          ...this.recordToUpdate,
        };
      }
      this.recordToUpdate = null;
    },
  },
};
</script>
<style>
#edit-schedule-btn.ant-btn {
  color: #ffc107;
  border-color: #fbe460;
}
#edit-schedule-btn.ant-btn:hover {
  color: #ffc107;
  border-color: #ffc107;
}

.fixed-height-modal {
  height: 600px;
  overflow: hidden;
}

.fixed-height-table {
  height: 340px;
  max-height: calc(100% - 150px);
  /* overflow-y: auto; */
}
</style>
