export const daysOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];

export const schedulingModeOptions = [
  { label: 'Idle', value: 'Idle' },
  { label: 'Recording', value: 'Recording' },
  { label: 'Guidance', value: 'Guidance' },
  { label: 'Guidance + Trace', value: 'Guidance + Trace' },
];

export const viewScheduleTableColumns = [
  {
    title: 'Day',
    dataIndex: 'day',
    align: 'center',
    key: 'Day',
    filters: daysOptions.map((option) => ({
      text: option.label,
      value: option.value,
    })),
    onFilter: (value, record) => record.day === value,

  },
  {
    title: 'Mode',
    dataIndex: 'mode',
    align: 'center',
    key: 'Mode',
    filters: schedulingModeOptions.map((option) => ({
      text: option.label,
      value: option.value,
    })),
    onFilter: (value, record) => record.mode === value,
  },
  {
    title: 'Start Time',
    dataIndex: 'start_time',
    align: 'center',
  },
  {
    title: 'State',
    dataIndex: 'is_running',
    align: 'center',
    key: 'State',
    filters: [
      { text: 'running', value: true },
      { text: 'not running', value: false },
    ],
    onFilter: (value, record) => record.is_running === value,
  },
  {
    title: 'Active',
    dataIndex: 'is_active',
    align: 'center',
  },
  {
    title: 'End Time',
    dataIndex: 'end_time',
    align: 'center',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    align: 'center',
  },
];
